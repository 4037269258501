/* eslint-disable react/prop-types */
import React, { Fragment } from 'react';
import { graphql } from 'gatsby';
import { getImage } from 'gatsby-plugin-image';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Seo from '../components/Seo';
import BrandGrid from '../components/BrandGrid/BrandGrid';
import Post from '../components/Blog/Post';
import Sidebar from '../components/Blog/Sidebar';
import Pagination from '../components/Blog/Pagination';

export const pageQuery = graphql`
  query WordpressPostArchiveQuery($skip: Int!, $limit: Int!) {
    allWpPost(
      sort: { fields: [date], order: DESC }
      skip: $skip
      limit: $limit
    ) {
      nodes {
        uri
        date(formatString: "MMMM DD, YYYY")
        title
        excerpt

        featuredImage {
          node {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(width: 1110)
              }
            }
          }
        }

        author {
          node {
            uri
            name
          }
        }

        categories {
          nodes {
            name
            uri
          }
        }
      }
    }
  }
`;

const BlogIndexPage = ({
  data,
  pageContext: { previousPagePath, nextPagePath },
}) => {
  const posts = data.allWpPost.nodes;

  return (
    <>
      <Seo
        title="Medical Web Experts Blog"
        description="Explore MWE's healthcare IT blog! Custom & turnkey mobile app, web dev & security for healthcare. Learn more"
      />

      <BrandGrid />

      <Container>
        <Row className="justify-content-md-center">
          <Col lg={8}>
            <header className="ui-header mb-5">
              <h1 className="h1--center text-center" itemProp="headline">
                Our Blog
              </h1>
            </header>
          </Col>
        </Row>
        <Row className="justify-content-lg-between">
          <Col xl={8}>
            <div>
              {posts.map((post) => {
                const featuredImage = {
                  image: getImage(post.featuredImage?.node?.localFile),
                  alt: post.featuredImage?.node?.altText || ``,
                };

                return (
                  <Fragment key={post.uri}>
                    <Post
                      uri={post.uri}
                      title={post.title}
                      featuredImage={featuredImage}
                      date={post.date}
                      author={post.author.node}
                      excerpt={post.excerpt}
                      categories={post.categories.nodes}
                    />
                    <hr className="no-border my-5" />
                  </Fragment>
                );
              })}
            </div>
            {(previousPagePath || nextPagePath) && (
              <Pagination previous={previousPagePath} next={nextPagePath} />
            )}
          </Col>
          <Col xl={3} as="aside" className="mt-5 mt-xl-0">
            <Sidebar />
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default BlogIndexPage;
